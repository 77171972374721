import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from "./ui/App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App/>
);
